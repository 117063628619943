import Css from "./style.module.scss";

import fallbackTexts from "assets/fallbackTexts.json";

import * as Intercom from "utils/Intercom";
import * as Sentry from "@sentry/browser";
import { hotjar as Hotjar } from "react-hotjar";
import { ImageViewer, ModalWindow } from "lib/common";
import { PushNotifications } from "@capacitor/push-notifications";
import { SplashScreen } from "@capacitor/splash-screen";
import { ToastContainer } from "react-toastify";
import { checkAuthCallbackState, getUserData } from "selectors/user";
import { checkPreloaderOverlayShown, checkPreloaderShown, getModalImages, getModalWindowConfig } from "selectors/ui";
import { getActiveOrganization } from "selectors/organizations";
import { getEnvVars } from "selectors/envVars";
import { getSelectedBusinessData, getUserBusinessesData } from "selectors/businesses";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import AccountsActions from "actions/AccountsActions";
import Async from "utils/Async";
import AuthZeroActions from "actions/AuthZeroActions";
import BrowserEvents from "const/BrowserEvents";
import BusinessesActions from "actions/BusinessesActions";
import Constants from "const/Constants";
import ContactsActions from "actions/ContactsActions";
import Countries from "const/Countries";
import Errors from "const/Errors";
import InstitutionsActions from "actions/InstitutionsActions";
import LocationHistoryContext from "contexts/LocationHistoryContext";
import MainApiActions from "actions/MainApiActions";
import MetaDataActions from "actions/MetaDataActions";
import ModalContainer from "nlib/common/ModalContainer";
import OrganizationsActions from "actions/OrganizationsActions";
import PreAccountingActions from "actions/PreAccountingActions";
import Preloader from "nlib/common/Preloader";
import React, { Suspense, lazy, useCallback, useEffect, useRef, useState } from "react";
import StripeActions from "actions/StripeActions";
import TasksActions from "actions/TasksActions";
import TextsActions from "actions/TextsActions";
import UiActions from "actions/UiActions";
import UiRoutes from "const/UiRoutes";
import UserActions from "actions/UserActions";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import VatClassificationActions from "actions/VatClassificationActions";
import useShowCommonModal from "hooks/useShowCommonModal";

const HOTJAR_ENABLED = false;

const OldApp = lazy(() => import("lib/App"));

const NewApp = lazy(() => import("nlib/App"));

const MobileApp = lazy(() => import("mlib/App"));

let initialViewportValue = null;

const toggleMobileViewportCompatibilityMode = () => {
  const portraitOrientation = window.innerHeight > window.innerWidth;

  const screenWidth = portraitOrientation || screen.width > screen.height ? window.screen.width : window.screen.height;

  const viewportMeta = document.querySelector("meta[name='viewport']");

  if (!initialViewportValue) {
    initialViewportValue = viewportMeta.getAttribute("content");
  }
  if (screenWidth <= Constants.LAYOUT_MIN_WIDTH) {
    viewportMeta.setAttribute("content", `width=${Constants.LAYOUT_MIN_WIDTH}`);
  } else {
    viewportMeta.setAttribute("content", initialViewportValue);
  }
};

const handleWindowResize = () => {
  toggleMobileViewportCompatibilityMode();
};

const checkBusinessIdValid = (businessId, businesses) => {
  return businessId && businesses.some(({ id }) => id === businessId);
};

const App = ({ onAppInitComplete }) => {
  const history = useHistory();

  const locationHistoryRef = useRef([]);

  const { params: { businessId: selectedBusinessId } } = useRouteMatch("/:businessId?");

  const { pathname, search } = useLocation();

  const route = `/${pathname.split("/")[1]}`;

  const dispatch = useDispatch();

  const envVars = useSelector(getEnvVars);

  const authZeroCallbackState = useSelector(checkAuthCallbackState);

  const preloaderShown = useSelector(checkPreloaderShown);

  const preloaderOverlayShown = useSelector(checkPreloaderOverlayShown);

  const modalWindowConfig = useSelector(getModalWindowConfig);

  const modalImages = useSelector(getModalImages);

  const activeOrganization = useSelector(getActiveOrganization);

  const userData = useSelector(getUserData);

  const userBusinessesData = useSelector(getUserBusinessesData);

  const selectedBusinessData = useSelector(getSelectedBusinessData);

  const showCommonModal = useShowCommonModal();

  const intervalsRef = useRef([]);

  const [initialDataLoaded, setInitialDataLoaded] = useState(false);

  const selectedBusinessIdValid = !!checkBusinessIdValid(selectedBusinessId, userBusinessesData);

  const businessUser = UserRoles.checkIsBusiness(userData.role);

  const czCountry = activeOrganization?.countryCode === Countries.CZ;

  const oldUi = czCountry && !businessUser;

  const handlePushNotificationsRegistration = useCallback(async({ value }) => {
    await dispatch(UserActions.editUser({
      pushToken: value,
      backgroundUpdate: true,
      silentUpdate: true
    }));
  }, [dispatch]);

  const handlePushNotificationsActionPerformed = useCallback(({ notification }) => {
    const { data: { navigateTo } = {} } = notification;

    if (navigateTo) window.location.href = navigateTo;
  }, []);

  const selectBusiness = useCallback(async(businessId) => {
    intervalsRef.current.forEach((intervalId) => clearInterval(intervalId));
    await dispatch(BusinessesActions.selectBusiness(businessId));
    if (czCountry) {
      dispatch(InstitutionsActions.fetchInstitutionLinks(true));
      dispatch(InstitutionsActions.fetchInstitutionAccounts(true));
    } else {
      await dispatch(AccountsActions.fetchAccountsList(true));
    }
    dispatch(BusinessesActions.fetchGlobalStats(true));
    dispatch(ContactsActions.fetchContactsList(true));
    dispatch(TasksActions.fetchTasksList(true));
    dispatch(UiActions.togglePreloader(false));
    intervalsRef.current.forEach((intervalId) => clearInterval(intervalId));
    intervalsRef.current = [
      setInterval(
        () => dispatch(BusinessesActions.fetchGlobalStats(false)),
        Constants.GLOBAL_STATS_UPDATE_INTERVAL
      ),
      setInterval(
        () => {
          dispatch(BusinessesActions.fetchBusiness(businessId, true, true));
          dispatch(TasksActions.fetchTasksList(false, true));
          if (!czCountry) {
            dispatch(AccountsActions.fetchAccountsList(false, true));
          }
        },
        Constants.DATA_LIST_UPDATE_INTERVAL
      ),
      setInterval(
        () => {
          dispatch(ContactsActions.fetchContactsList(false, true));
          if (czCountry) {
            dispatch(InstitutionsActions.fetchInstitutionLinks(false, true));
            dispatch(InstitutionsActions.fetchInstitutionAccounts(false, true));
          }
        },
        Constants.CONTACTS_DATA_UPDATE_INTERVAL
      )
    ];
  }, [czCountry, dispatch]);

  const fetchInitialData = useCallback(async() => {
    await dispatch(TextsActions.setAppLanguage(null));
    if (Utils.checkIsNativePlatform()) {
      await PushNotifications.addListener("pushNotificationActionPerformed", handlePushNotificationsActionPerformed);
    }
    if (authZeroCallbackState) {
      const { appState } = await dispatch(AuthZeroActions.handleRedirectCallback()) || {};

      if (appState && appState.returnTo) window.location.replace(atob(appState.returnTo));
      else history.replace(UiRoutes.MAIN);
    }

    const token = await dispatch(AuthZeroActions.fetchAuthToken(authZeroCallbackState));

    if (!token) {
      dispatch(UiActions.togglePreloader(false));

      return false;
    }
    if (route === UiRoutes.LOGOUT) {
      dispatch(AuthZeroActions.logoutUser());

      return false;
    }
    dispatch(MainApiActions.setAuthToken(token));
    if (envVars.checkoutSessionId) {
      await dispatch(StripeActions.fullfillCheckoutSession(envVars.checkoutSessionId));
      history.replace(UiRoutes.MAIN);
    }

    // It's important to make first api call separately, and then call others (in parallel or ordered)
    const organizations = await dispatch(OrganizationsActions.fetchOrganizationsList());

    const [userInfo, users, businesses] = await Promise.all([
      dispatch(AuthZeroActions.fetchUserInfo()),
      dispatch(OrganizationsActions.fetchUsersList()),
      dispatch(BusinessesActions.fetchBusinessesList(true, false))
    ]);

    const currentUser = (users || []).find(({ sub }) => sub === userInfo.sub) || {};

    if (!userInfo || !users || !currentUser || !organizations || !businesses) {
      throw Errors.CRITICAL_DATA_NOT_LOADED;
    }

    const currentActiveOrganization = organizations.find(({ active }) => active);

    const czCountryInternal = currentActiveOrganization.countryCode === Countries.CZ;

    try {
      Sentry.setContext("userData", currentUser);
      Sentry.setTag("userEmail", currentUser.email);
    } catch (error) {}
    try {
      if (HOTJAR_ENABLED && Hotjar.initialized()) {
        Hotjar.identify(
          null,
          {
            user: currentUser.email.replace(/@/, "/"),
            name: `${currentUser.firstName || ""} ${currentUser.lastName || ""}`.trim(),
            role: currentUser.role,
            guestUser: !!currentUser.guestUser,
            organization: currentActiveOrganization.name
          }
        );
      }
    } catch (error) {}

    if (Utils.checkIsTouchDevice()
      && currentActiveOrganization.countryCode === Countries.CZ
      && !UserRoles.checkIsBusiness(currentUser.role)) {
      const { RESIZE, FULLSCREEN_CHANGE, ORIENTATION_CHANGE } = BrowserEvents;

      // eslint-disable-next-line require-atomic-updates
      window.document.body.dataset.oldUi = "";

      toggleMobileViewportCompatibilityMode();
      window.addEventListener(RESIZE, handleWindowResize);
      window.addEventListener(FULLSCREEN_CHANGE, handleWindowResize);
      window.addEventListener(ORIENTATION_CHANGE, handleWindowResize);
    }

    if (!UserRoles.checkIsBusiness(currentUser.role)
      && !Utils.checkIsDevMode()
      && !Utils.checkIsTouchDevice()) {
      Intercom.init(currentUser, currentActiveOrganization);
    }

    if (currentActiveOrganization.temp) {
      setTimeout(() => dispatch(AuthZeroActions.logoutUser()), Constants.AUTO_LOGOUT_FOR_TEMP_USER);
    }
    if (!Utils.checkIsDevMode() && !Utils.checkIsVercelDomain()) {
      const { DEFAULT_APP_URL } = Utils.getProcessEnvVars();

      const { domainName } = currentActiveOrganization.whiteLabel || {};

      if (domainName && domainName !== window.location.host) {
        window.location.replace(`//${domainName}${window.location.pathname}${window.location.search}`);
        await Async.waitForever();
      } else if (!domainName && !Utils.checkIsDefaultAppDomain()) {
        window.location.replace(`${DEFAULT_APP_URL}${window.location.pathname}${window.location.search}`);
        await Async.waitForever();
      }
    }

    const [currencies, prices, banks, institutions] = await Promise.all([
      dispatch(MetaDataActions.fetchCurrenciesList()),
      dispatch(MetaDataActions.fetchPricesList()),
      ...(czCountryInternal ? [
        dispatch(MetaDataActions.fetchBanksList()),
        dispatch(InstitutionsActions.fetchInstitutionsList())
      ] : [])
    ]);

    if (!currencies || !prices || (czCountryInternal && (!banks || !institutions))) throw Errors.CRITICAL_DATA_NOT_LOADED;
    setInitialDataLoaded(true);
    if (Utils.checkIsNativePlatform() && UserRoles.checkIsBusiness(currentUser.role)) {
      (async() => {
        let pushNotificationsPermissions = false;

        ({ receive: pushNotificationsPermissions } = await PushNotifications.checkPermissions());
        if (pushNotificationsPermissions === "prompt") {
          ({ receive: pushNotificationsPermissions } = await PushNotifications.requestPermissions());
        }
        if (pushNotificationsPermissions === "granted") {
          await PushNotifications.addListener("registration", handlePushNotificationsRegistration);
          await PushNotifications.register();
        }
      })();
    }

    const userBusinessIds = currentUser.businessIds || [];

    const userBusinesses = businesses.filter(({ id }) => !userBusinessIds.length || userBusinessIds.includes(id));

    const singleBusiness = userBusinesses.length === 1;

    const innerBusinessUser = UserRoles.checkIsBusiness(currentUser.role);

    if (!userBusinesses.length || (
      !Utils.checkIsTouchDevice() && (
        ((route === UiRoutes.MAIN) && !innerBusinessUser && !singleBusiness)
        || (route === UiRoutes.SETTINGS && !innerBusinessUser)
        || (`/${selectedBusinessId}` === UiRoutes.INTEGRATIONS))
    )) {
      dispatch(UiActions.togglePreloader(false));

      return true;
    }

    if (checkBusinessIdValid(selectedBusinessId, userBusinesses)) return true;

    if (!Utils.checkIsTouchDevice() && !innerBusinessUser && !singleBusiness) {
      history.push(UiRoutes.MAIN);
      dispatch(UiActions.togglePreloader(false));

      return true;
    }

    let fallbackBusinessId = Utils.storageValue(Constants.LS_KEYS.SELECTED_BUSINESS);

    if (checkBusinessIdValid(fallbackBusinessId, userBusinesses)) {
      history.push(`/${fallbackBusinessId}`);

      return true;
    }

    fallbackBusinessId = (userBusinesses.find(({ id }) => id === currentActiveOrganization.businessId) || userBusinesses[0]).id;
    history.push(`/${fallbackBusinessId}`);

    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async() => {
      try {
        const authorized = await fetchInitialData();

        if (authorized && Utils.checkIsNativePlatform()) {
          try {
            await SplashScreen.hide();
          } catch (error) {}
        }
        if (onAppInitComplete) onAppInitComplete();
      } catch (errorA) {
        if (Utils.checkIsNativePlatform()) {
          try {
            await SplashScreen.hide();
          } catch (errorB) {}
        }
        if (Utils.checkIsDevMode()) console.error(errorA); //eslint-disable-line

        const { uiTexts, errors } = fallbackTexts;

        setTimeout(() => { window.location.reload(); }, Constants.AUTO_PAGE_RELOAD_ON_ERROR_DELAY);

        const modalResult = await showCommonModal({
          text: errors.loadingApp,
          headerText: uiTexts.error,
          confirm: true,
          okButtonText: uiTexts.reload,
          cancelButtonText: uiTexts.logout
        });

        if (modalResult) window.location.reload();
        else await dispatch(AuthZeroActions.logoutUser());
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!initialDataLoaded || !selectedBusinessId || !selectedBusinessIdValid || !(activeOrganization || {}).countryCode) return;

    selectBusiness(selectedBusinessId);
  }, [initialDataLoaded, selectedBusinessId, selectedBusinessIdValid, activeOrganization, selectBusiness]);

  useEffect(() => {
    locationHistoryRef.current.push({ pathname, search });
  }, [pathname, search]);

  useEffect(() => {
    if (activeOrganization?.countryCode === Countries.CZ && selectedBusinessData.id) {
      dispatch(VatClassificationActions.fetch(true));
      dispatch(PreAccountingActions.fetch(true));
    }
  }, [activeOrganization?.countryCode, selectedBusinessData.id, dispatch]);

  useEffect(() => {
    if (!czCountry) return () => {};

    dispatch(InstitutionsActions.fetchLinksExpirationInfo());

    return Utils.setInterval(() => {
      dispatch(InstitutionsActions.fetchLinksExpirationInfo());
    }, Constants.INSTITUTION_LINKS_EXPIRATION_UPDATE_INTERVAL);
  }, [czCountry, dispatch]);

  return (
    <LocationHistoryContext.Provider value={locationHistoryRef}>
      {preloaderShown
        ? !modalWindowConfig && <Preloader fixed />
        : (
          <Suspense fallback={<Preloader fixed />}>
            {oldUi ? <OldApp selectBusiness={selectBusiness} /> : (
              Utils.checkIsTouchDevice() ? <MobileApp /> : <NewApp />
            )}
          </Suspense>
        )}
      {modalImages && <ImageViewer sources={modalImages} />}
      {modalWindowConfig && (
        oldUi
          ? <ModalWindow appModal config={modalWindowConfig} />
          : <ModalContainer />
      )}
      <ToastContainer
        autoClose={Constants.NOTIFICATION_AUTO_CLOSE_DELAY}
        className={Css.toastContainer}
        toastClassName={Css.toast} />
      {preloaderOverlayShown && <Preloader className={Css.overlayPreloader} fixed />}
    </LocationHistoryContext.Provider>
  );
};

export default React.memo(App);
